import React from "react";

import "swiper/css";
import "swiper/css/navigation";

const WhyChooseDrhs = () => {
  const cards = [
    {
      id: "flexible-options",
      title: "Flexible and Convenient Learning Options",
      points: [
        "Online Tutor-Led Training: Our course is exclusively delivered through live virtual sessions with expert instructors in an interactive session.",
        "Weekend and Weekday Options: Select a schedule that aligns with your professional commitments.",
      ],
    },
    {
      id: "expert-trainers",
      title: "Expert Trainers with Industry Insights",
      points: [
        "CITB-Approved Instructors: Experts with extensive experience in senior health and safety leadership.",
        "Practical Case Studies: Learn from real-world examples of best practices and compliance challenges.",
      ],
    },
    {
      id: "proven-success",
      title: "High Certification Success Rate",
      points: [
        "99% Pass Rate: Our structured training ensures a high success rate for participants.",
        "Comprehensive Support: Personalized guidance throughout the course to help you succeed.",
      ],
    },
    {
      id: "recognized-certification",
      title: "Nationally Recognized Certification",
      points: [
        "CITB-Accredited Certificate: Valid for 5 years, demonstrating your commitment to health and safety leadership.",
        "Improved Business Compliance: Certification ensures compliance with UK regulatory requirements.",
      ],
    },
    {
      id: "interactive-training",
      title: "Interactive and Engaging Training Approach",
      points: [
        "Live Discussions & Group Exercises: Enhance understanding through interactive learning.",
        "Real-World Scenarios: Apply learning directly to your business operations.",
      ],
    },
    {
      id: "comprehensive-support",
      title: "Comprehensive Support",
      points: [
        "Digital Resources: Access course materials before, during, and after the training.",
        "Post-Course Guidance: Ongoing support for certification renewal and staying up-to-date with regulations.",
      ],
    },
    {
      id: "value-money",
      title: "Value for Money",
      points: [
        "Transparent Pricing: No hidden fees—course costs cover all materials, training, and certification.",
        "Corporate Discounts: Cost-effective training for multiple directors from the same company.",
      ],
    },
    {
      id: "proven-track-record",
      title: "Proven Track Record",
      points: [
        "Trusted by Industry Leaders: Hundreds of directors and executives rely on us for their compliance training.",
        "Positive Feedback: Participants praise our expertise, clarity, and real-world application.",
      ],
    },
    {
      id: "additional-benefits",
      title: "Additional Benefits",
      points: [
        "Ongoing Updates: Receive health and safety updates after the course to keep your knowledge current.",
        "Networking Opportunities: Connect with peers in the construction industry during the training.",
      ],
    },
  ];
  

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
         Why Choose Us for Your DRHS Training?         </h2>
        <p className="text-white">
        Our CITB-accredited Director’s Role for Health and Safety course offers a focused, high-quality learning experience tailored for senior executives. Here’s why directors prefer our training: 
        </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-[#FF3131] mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
      Completing this course, directors will ensure their businesses remain compliant, safe, and legally protected from potential liabilities. 
      </p>
    </section>
  );
};

export default WhyChooseDrhs;
