import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const titles = {
      "/": "M2HSE Training | CITB ATO | NVQs & Construction Courses",
      "/about-us": "About Us | M2HSE",
      "/contact-us": "Contact Us | M2HSE",
      "/privacy-policy": "Privacy Policy | M2HSE",
      "/refund-policy": "Refund Policy | M2HSE",
      "/cookies-policy": "Cookies Policy | M2HSE",
      "/thank-you": "Thank you | M2HSE",
      "/term-and-conditions": "Term and Conditions | M2HSE",
      "/citb-courses": "CITB Courses | M2HSE",
      "/cscs-cards": "CSCS cards | M2HSE",
      "/nvq-level-courses": "NVQ Level Courses | M2HSE",
      "/nvq-level-1": "NVQ Level 1 | M2HSE",
      "/nvq-level-2": "NVQ Level 2 | M2HSE",
      "/nvq-level-3": "NVQ Level 3 | M2HSE",
      "/nvq-level-4": "NVQ Level 4 | M2HSE",
      "/nvq-level-5": "NVQ Level 5 | M2HSE",
      "/nvq-level-6": "NVQ Level 6 | M2HSE",
      "/nvq-level-7": "NVQ Level 7 | M2HSE",
      "/nvq-level-4-demolition": "NVQ Level 4 Demolition | M2HSE",
      "/nvq-level-4-retrofit": "NVQ Level 4 Retrofit | M2HSE",
      "/green-cscs-card-course": "Green CSCS Card Course | M2HSE",
      "/level-2-future-workplace-in-construction-environment": "Future Workplace in Construction | M2HSE",
      "/level-2-green-ambassador-within-the-construction-industry": "Green Ambassador in Construction | M2HSE",
      "/level-2-green-ambassador-within-the-retrofit-sector": "Green Ambassador in Retrofit | M2HSE",
      "/level-2-rainscreen-cladding-installation": "Rainscreen Cladding Installation | M2HSE",
      "/level-2-recognising-damp-mould-and-condensation-buildings": "Recognising Damp, Mould & Condensation | M2HSE",
      "/level-2-construction-plant-operations": "Construction Plant Operations | M2HSE",
      "/level-2-articulated-drawbar-vehicle": "Articulated Drawbar Vehicle | M2HSE",
      "/level-2-motor-cycle-pedal-cycle": "Motor Cycle & Pedal Cycle | M2HSE",
      "/level-2-rigid-vehicle": "Rigid Vehicle | M2HSE",
      "/level-2-van": "Van | M2HSE",
      "/level-2-forklift": "Forklift | M2HSE",
      "/level-2-highways-maintenance-excavation": "Highways Maintenance Excavation | M2HSE",
      "/level-2-highways-maintenance-kerbs": "Highways Maintenance Kerbs | M2HSE",
      "/level-2-damage-repair-and-resurfacing": "Damage Repair & Resurfacing | M2HSE",
      "/level-2-facade-preservation": "Facade Preservation | M2HSE",
      "/level-2-installing-protective-components": "Installing Protective Components | M2HSE",
      "/level-2-water-jetting": "Water Jetting | M2HSE",
      "/level-2-cold-formed-steel-frame-assembly": "Cold Formed Steel Frame Assembly | M2HSE",
      "/level-2-erection-precast-concrete-cladding": "Erection of Precast Concrete Cladding | M2HSE",
      "/level-2-erection-precast-concrete-flooring": "Erection of Precast Concrete Flooring | M2HSE",
      "/level-2-fitted-interiors": "Fitted Interiors | M2HSE",
      "/level-2-cavity-barrier": "Cavity Barrier | M2HSE",
      "/level-2-ceiling-fixing": "Ceiling Fixing | M2HSE",
      "/level-2-dry-lining-finishing": "Dry Lining Finishing | M2HSE",
      "/level-2-dry-lining-boarder": "Dry Lining Boarder | M2HSE",
      "/level-2-non-hazardous-waste": "Non-Hazardous Waste | M2HSE",
      "/level-2-movement-guide": "Movement Guide | M2HSE",
      "/level-2-loading-and-security": "Loading & Security | M2HSE",
      "/level-2-laying-and-distributing": "Laying & Distributing | M2HSE",
      "/level-2-fork-lift-trucks": "Fork Lift Trucks | M2HSE",
      "/level-2-extracting": "Extracting | M2HSE",
      "/level-2-excavating": "Excavating | M2HSE",
      "/level-2-cranes-and-specialist-lifting": "Cranes & Specialist Lifting | M2HSE",
      "/level-2-compacting": "Compacting | M2HSE",
      "/level-2-attachments": "Attachments | M2HSE",
      "/level-2-operations-guide": "Operations Guide | M2HSE",
      "/level-2-processing": "Processing | M2HSE",
      "/level-2-road-and-rail": "Road & Rail | M2HSE",
      "/level-2-sweeping-cleaning-clearing": "Sweeping, Cleaning & Clearing | M2HSE",
      "/level-2-transporting-loads": "Transporting Loads | M2HSE",
      "/level-2-work-platforms": "Work Platforms | M2HSE",
      "/level-2-excavation": "Excavation | M2HSE",
      "/level-2-excavation-and-reinstatement": "Excavation & Reinstatement | M2HSE",
      "/level-2-laying-kerbs-and-channels": "Laying Kerbs & Channels | M2HSE",
      "/level-2-locate-and-excavate-utilities": "Locate & Excavate Utilities | M2HSE",
      "/level-2-maintenance-response-team": "Maintenance Response Team | M2HSE",
      "/level-2-manual-road-building-flexible-pavement": "Manual Road Building - Flexible Pavement | M2HSE",
      "/level-2-modular-pavement-construction": "Modular Pavement Construction | M2HSE",
      "/level-2-reinstatement": "Reinstatement | M2HSE",
      "/level-2-road-sweeping-machine": "Road Sweeping Machine | M2HSE",
      "/level-2-street-iron-work": "Street Iron Work | M2HSE",
      "/level-2-blinds-and-solar-shading-systems": "Blinds & Solar Shading Systems | M2HSE",
      "/level-2-industrial-storage-systems-inspection": "Industrial Storage Systems Inspection | M2HSE",
      "/level-2-industrial-storage-systems-maintenance": "Industrial Storage Systems Maintenance | M2HSE",
      "/level-2-joint-sealant-applications": "Joint Sealant Applications | M2HSE",
      "/level-2-loading-bay-equipment-installation": "Loading Bay Equipment Installation | M2HSE",
      "/level-2-point-of-purchase": "Point of Purchase | M2HSE",
      "/level-2-roof-lining-systems": "Roof Lining Systems | M2HSE",
      "/level-2-wall-and-floor-tiling": "Wall & Floor Tiling | M2HSE",
      "/level-2-green-roof-installer": "Green Roof Installer | M2HSE",
      "/level-2-green-roof-seeding": "Green Roof Seeding | M2HSE",
      "/level-2-green-roof-support": "Green Roof Support | M2HSE",
      "/nvq-level-4-traditional-And-heritage-building": "Traditional & Heritage Building Level 4 | M2HSE",
      "/nvq-level-4-highways-maintenance-and-repair": "Highways Maintenance & Repair Level 4 | M2HSE",
      "/nvq-level-4-residential-development": "Residential Development Level 4 | M2HSE",
      "/nvq-level-4-awards": "NVQ Level 4 Awards | M2HSE",
      "/nvq-level-4-tunnelling": "Tunnelling Level 4 | M2HSE",
      "/nvq-level-4-supervising-lifts": "Supervising Lifts Level 4 | M2HSE",
      "/nvq-level-4-building-and-civil-engineering": "Building & Civil Engineering Level 4 | M2HSE",
      "/sssts-online-course": "SSSTS Online Course | M2HSE",
      "/sssts-online-course-detail/:id": "SSSTS Course Detail | M2HSE",
      "/sssts-refresher-course": "SSSTS Refresher Course | M2HSE",
      "/sssts-refresher-course-detail/:id": "SSSTS Refresher Course Detail | M2HSE",
      "/smsts-online-course": "SMSTS Online Course | M2HSE",
      "/smsts-online-course-detail/:id": "SMSTS Course Detail | M2HSE",
      "/smsts-refresher-course": "SMSTS Refresher Course | M2HSE",
      "/smsts-refresher-course-detail/:id": "SMSTS Refresher Course Detail | M2HSE",
      "/the-director-role-for-health-and-safety": "Director Role for Health & Safety | M2HSE",
      "/citb-temporary-works-supervisor-training": "CITB Temporary Works Supervisor Training | M2HSE",
      "/temporary-works-co-ordinator-training": "Temporary Works Co-ordinator Training | M2HSE",
      "/director-role-health-safety-detail/:id": "Director Role Health & Safety Detail | M2HSE",
      "/citb-temporary-works-supervisor-training-detail/:id": "Temporary Works Supervisor Training Detail | M2HSE",
      "/temporary-works-co-ordinator-training-detail/:id": "Temporary Works Co-ordinator Training Detail | M2HSE",
      "/nvq-level-7-construction-senior-management": "Construction Senior Management Level 7 | M2HSE",
      "/nvq-level-3-health&safety": "Health & Safety Level 3 | M2HSE",
      "/nvq-level-6-health&safety-practice": "Health & Safety Practice Level 6 | M2HSE",
      "/business-solution": "Business Solution | M2HSE",
      "/nvq-level-7-Strategic-Health-and-Safety-Leadership-and-Management": "Strategic Health & Safety Leadership Level 7 | M2HSE",
      "/nvq-level-6-residential-dvelopment-pathway": "Residential Development Pathway Level 6 | M2HSE",
      "/nvq-level-6-traditional-and-heritage-building": "Traditional & Heritage Building Level 6 | M2HSE",
      "/nvq-level-6-building-and--civil-engireering-Pathway": "Building & Civil Engineering Pathway Level 6 | M2HSE",
      "/level-6-construction-in-site-management-demolition": "Construction Site Management - Demolition Level 6 | M2HSE",
      "/level-6-construction-in-site-management-highways-maintenance-and-repair": "Construction Site Management - Highways Maintenance & Repair Level 6 | M2HSE",
      "/level-6-construction-in-site-management-senior-site-inspection": "Construction Site Management - Senior Site Inspection Level 6 | M2HSE",
      "/level-6-construction-in-site-management-contracting-operations-management": "Construction Site Management - Contracting Operations Level 6 | M2HSE",
      "/level-3-award-in-preparing-for-the-future-workplace": "Award in Preparing for the Future Workplace Level 3 | M2HSE",
      "/level-3-pipework-components": "Pipework Components Level 3 | M2HSE",
      "/level-3-moving-loads-construction": "Moving Loads in Construction Level 3 | M2HSE",
      "/level-3-plant-maintenance": "Plant Maintenance Level 3 | M2HSE",
      "/level-3-shoring-and-pipe-laying": "Shoring & Pipe Laying Level 3 | M2HSE",
      "/level-3-surveying": "Surveying Level 3 | M2HSE",
      "/level-3-site-technical-support": "Site Technical Support Level 3 | M2HSE",
      "/level-3-temporary-suspended-access": "Temporary Suspended Access Level 3 | M2HSE",
      "/level-3-steeplejacking": "Steeplejacking Level 3 | M2HSE",
      "/level-3-scaffolding-and-offshore": "Scaffolding & Offshore Level 3 | M2HSE",
      "/level-3-lighting-protective-system": "Lighting Protective System Level 3 | M2HSE",
      "/level-3-lighting-protection-engineer": "Lighting Protection Engineer Level 3 | M2HSE",
      "/level-3-rain-screen-wall-cladding-system": "Rain Screen Wall Cladding System Level 3 | M2HSE",
      "/level-3-roof-and-wall-sheeting": "Roof & Wall Sheeting Level 3 | M2HSE",
      "/level-3-buying": "Buying Level 3 | M2HSE",
      "/level-3-roof-slater": "Roof Slater Level 3 | M2HSE",
      "/level-3-roof-tiler": "Roof Tiler Level 3 | M2HSE",
      "/level-3-roof-slater-tiler": "Roof Slater & Tiler Level 3 | M2HSE",
      "/level-3-design-coordinator": "Design Coordinator Level 3 | M2HSE",
      "/level-3-estimating": "Estimating Level 3 | M2HSE",
      "/level-3-general": "General Level 3 | M2HSE",
      "/level-3-planning": "Planning Level 3 | M2HSE",
      "/level-3-welding-construction": "Welding Construction Level 3 | M2HSE",
      "/level-3-decorative-finishing": "Decorative Finishing Level 3 | M2HSE",
      "/level-3-demolition-chargehand": "Demolition Chargehand Level 3 | M2HSE",
      "/level-3-demolition-operative": "Demolition Operative Level 3 | M2HSE",
      "/level-3-demolition-plant": "Demolition Plant Level 3 | M2HSE",
      "/level-3-external-wall-insulation-boarder": "External Wall Insulation Boarder Level 3 | M2HSE",
      "/level-3-room-in-roof": "Room in Roof Level 3 | M2HSE",
      "/level-3-park-homes": "Park Homes Level 3 | M2HSE",
      "/level-3-internal-insulation-walls": "Internal Insulation Walls Level 3 | M2HSE",
      "/level-3-insulating-framed-sections": "Insulating Framed Sections Level 3 | M2HSE",
      "/level-3-hybrid-wall": "Hybrid Wall Level 3 | M2HSE",
      "/level-3-external-wall-insulation-finisher": "External Wall Insulation Finisher Level 3 | M2HSE",
      "/level-3-external-wall-insulation-boarder-and-finisher": "External Wall Insulation Boarder & Finisher Level 3 | M2HSE",
      "/level-3-complex-suspended-ceiling-systems": "Complex Suspended Ceiling Systems Level 3 | M2HSE",
      "/level-3-service-operable-partition-walls": "Service Operable Partition Walls Level 3 | M2HSE",
      "/level-3-stretched-ceiling-fixing": "Stretched Ceiling Fixing Level 3 | M2HSE",
      "/level-3-plastering-solid": "Plastering Solid Level 3 | M2HSE",
      "/level-3-plastering-fibrous": "Plastering Fibrous Level 3 | M2HSE",
      "/level-3-diploma-in-plant-installations-tower-crane": "Diploma in Plant Installations - Tower Crane Level 3 | M2HSE",
      "/level-3-diploma-in-plant-installations-hoist": "Diploma in Plant Installations - Hoist Level 3 | M2HSE",
      "/level-3-diploma-in-wall-and-floor-tiling": "Diploma in Wall & Floor Tiling Level 3 | M2HSE",
      "/level-3-diploma-in-trowel-occupation": "Diploma in Trowel Occupation Level 3 | M2HSE",
      "/level-3-diploma-in-stonemasonry-memorial-masonry": "Diploma in Stonemasonry - Memorial Masonry Level 3 | M2HSE",
      "/level-3-diploma-in-stonemasonry-banker-masonry": "Diploma in Stonemasonry - Banker Masonry Level 3 | M2HSE",
      "/level-3-diploma-in-steel-fixing-occupation": "Diploma in Steel Fixing Occupation Level 3 | M2HSE",
      "/level-3-architectural-joinery": "Architectural Joinery Level 3 | M2HSE",
      "/level-3-shoplifitting-bench-work": "Shoplifting Bench Work Level 3 | M2HSE",
      "/level-3-shoplifitting-site-work": "Shoplifting Site Work Level 3 | M2HSE",
      "/level-3-site-carpentry": "Site Carpentry Level 3 | M2HSE",
      "/level-3-structure-post-and-beam": "Structure Post & Beam Level 3 | M2HSE",
      "/level-3-timber-frame-erection": "Timber Frame Erection Level 3 | M2HSE",
      "/level-3-wheel-wrighting": "Wheel Wrighting Level 3 | M2HSE",
      "/level-3-formwork": "Formwork Level 3 | M2HSE",
      "/level-3-heritage-skills-fully-supported-lead-and-hard-metal-roofing":
        "Heritage Skills - Lead & Hard Metal Roofing Level 3 | M2HSE",
      "/level-3-heritage-skills-mason": "Heritage Skills - Mason Level 3 | M2HSE",
      "/level-3-heritage-skills-roof-slatting-and-tiling-occupations":
        "Heritage Skills - Roof Slatting & Tiling Level 3 | M2HSE",
      "/level-3-heritage-skills-wood-occupations": "Heritage Skills - Wood Occupations Level 3 | M2HSE",
      "/level-3-machinery-maintenance": "Machinery Maintenance Level 3 | M2HSE",
      "/level-3-occupation-work-supervision": "Occupation Work Supervision Level 3 | M2HSE",
      "/level-3-dynamic-pile-testing": "Dynamic Pile Testing Level 3 | M2HSE",
      "/level-3-leak-detection-inwaterproof-systems": "Leak Detection in Waterproof Systems Level 3 | M2HSE",
      "/level-5-award-in-preparing-for-the-future-workplace": "Award in Preparing for the Future Workplace Level 5 | M2HSE",
      "/level-5-nvq-diploma-in-controlling-lifting-planning-lifts": "NVQ Diploma in Controlling Lifting & Planning Lifts Level 5 | M2HSE",
      "/nvq-level-6-tunneling": "NVQ Level 6 - Tunneling | M2HSE",
      "/nvq-level-6-retrofit": "NVQ Level 6 - Retrofit | M2HSE",
      "/cart-checkout": "Cart & Checkout | M2HSE",

    };

    document.title = titles[location.pathname] || "M2HSE - Training & Certification";
  }, [location]);
};

export default usePageTitle;
