import React, { useState } from "react";
import ContactPageModal from "../../screens/Contact_Us/CITBContactForm/ContactFormCitb";

const Popup = ({ show, onClose }) => {
  if (!show) return null;

  return (
    // <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-50">
    //   <div className="bg-white p-6 rounded-xl shadow-xl w-96 md:w-1/3">
    //     <h2 className="text-2xl text-[#FF3131] font-bold mb-4">
    //       Find the Perfect Course?
    //     </h2>
    //     <p className="mb-6">
    //       Consult our expert advisors to discover the ideal course that aligns
    //       with your goals and aspirations.
    //     </p>
    //     <div className="flex space-x-4">
    //       <ContactPageModal />
    //       <button
    //         className="text-red-500 hover:text-red-600 font-semibold py-3 px-6 rounded-lg"
    //         onClick={onClose}>
    //         Close
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-50">
  <div className="bg-white p-8 rounded-2xl shadow-2xl w-96 md:w-1/3 text-center">
    {/* Offer Badge */}
    <div className="bg-[#FF3131] text-white text-sm font-bold py-1 px-3 rounded-full inline-block mb-3">
      Limited Time Offer!
    </div>

    {/* Headline */}
    <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
      🎉 Special Discount on 
      <span className="text-[#FF3131]"> SMSTS Course!</span>
    </h2>

    {/* Description */}
    <p className="text-gray-700 mb-4 text-lg">
      Grab this exclusive deal before it’s gone!  
      Get certified at a <span className="text-red-500 font-bold" >£390+VAT</span> and level up your career.  
    </p>

    <p className="text-gray-700 mb-6 text-sm">
      Offer valid just for  {" "} 
      <span className="text-red-500 font-bold" >03/03/25</span> to <span className="text-red-500 font-bold" >07/07/25</span> slot
    </p>

    {/* Call to Action */}
    <div className="flex flex-col space-y-4">
      <a
        href="/smsts-online-course" 
        className="bg-[#FF3131] hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-lg text-lg shadow-lg transition duration-300"
      >
        Explore Now 🚀 
      </a>
      <button
        className="text-gray-600 hover:text-gray-900 font-semibold py-2"
        onClick={onClose}
      >
        No, Thanks
      </button>
    </div>
  </div>
</div>


  );
};

export default Popup;
