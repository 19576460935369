import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const WhyToChooseTwstc = () => {
  const cards = [
    {
      id: "flexible-options",
      title: "Flexible and Convenient Learning Options",
      points: [
        "Online Tutor-Led Training– Participate in virtual & interactive sessions from anywhere.",
        "Weekday & Weekend Availability– Choose a schedule that suits your work commitments.",
      ],
    },
    {
      id: "expert-trainers",
      title: "Expert Trainers with Industry Experience",
      points: [
        "CITB-Approved Instructors– Experts with hands-on experience in temporary works.",
        "Real-World Case Studies– Learn from past failures and best practices.",
      ],
    },
    {
      id: "proven-success",
      title: "High Certification Success Rate",
      points: [
        "99% Pass Rate– Our structured training ensures candidates achieve success.",
        "Comprehensive Support– Guidance throughout the course for optimal learning.",
      ],
    },
    {
      id: "recognized-certification",
      title: "Nationally Recognized Certification",
      points: [
        "CITB-Accredited Certificate– Valid for 5 years, demonstrating competency in temporary works supervision.",
        "Industry Recognition– Enhances career prospects and site compliance.",
      ],
    },
    {
      id: "interactive-training",
      title: "Engaging and Interactive Training",
      points: [
        "Live Discussions & Q&A– Interactive learning experience.",
        "Practical Scenarios– Apply knowledge directly to real-site conditions.",
      ],
    },
    {
      id: "comprehensive-support",
      title: "Comprehensive Support",
      points: [
        "Digital Resources– Access course materials before, during, and after the training.",
        "Post-Course Guidance– Ongoing support for certification renewal and staying up-to-date with regulations.",
      ],
    },
    {
      id: "value-money",
      title: "Value for Money",
      points: [
        "Transparent Pricing– No hidden fees; all materials, training, and certification included.",
        "Corporate Discounts Available– Ideal for businesses training multiple supervisors.",
      ],
    },
    {
      id: "proven-track-record",
      title: "Proven Track Record",
      points: [
        "Trusted by Industry Professionals– Highly recommended by previous participants.",
        "5-Star Reviews– Rated highly for course quality and expert instruction.",
      ],
    },
    {
      id: "additional-benefits",
      title: "Additional Benefits",
      points: [
        "Ongoing Updates– Receive health and safety updates after the course to keep your knowledge current.",
        "Networking Opportunities– Connect with peers in the construction industry during the training.",
      ],
    },
  ];
  
  

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
         Why Choose Us for Your TWSTC Training?         </h2>
        <p className="text-white">
        Our CITB-accredited Temporary Works Supervisor Training Course (TWSTC) offers a structured and engaging learning experience. Here’s why professionals prefer our training:         </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-[#FF3131] mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
      Completing this course, directors will ensure their businesses remain compliant, safe, and legally protected from potential liabilities. 
      </p>
    </section>
  );
};

export default WhyToChooseTwstc;
