import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FiCheckCircle, FiChevronDown, FiChevronUp } from "react-icons/fi";
import citbLogo from "../../../assets/CitbPage/citb_logo.png";
import sectionImage from "../../../assets/CitbPage/image 11.svg";
import WhyToChooseTwstc from "./WhyToChooseTwstc";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useCart } from "../../../CartContext";
import { RxCalendar } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoMdPeople } from "react-icons/io";
import ContactPageModal from "../../Contact_Us/CITBContactForm/ContactFormCitb";
import TrustPilot from "../TrustPilot/TrustPilot";
import TrustpilotWidget from "../TrustPilot/TrustPilot";
import Calendar from "../Calender/Calendar";
const TemporaryWorksSupervisionTrainingCourseDetail = () => {
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
  const { id } = useParams();
  const [slot, setSlot] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToCart } = useCart();

  function formatDateRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDay = start.getDate().toString().padStart(2, "0");
    const startMonth = monthNames[start.getMonth()];

    const endDay = end.getDate().toString().padStart(2, "0");
    const endMonth = monthNames[end.getMonth()];

    const timeDifference = end - start;
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

    const dateRange = ` ${startDay}-${startMonth}`;

    return { dateRange, days: `${numberOfDays} Day` };
  }

  useEffect(() => {
    const fetchSlot = async () => {
      try {
        const response = await axios.get(`/slots/read/${id}`);
        setSlot(response.data.result);
        console.log(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSlot();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const getTimeFromDate = (dateStr) => {
    const date = new Date(dateStr);

    return date.toLocaleTimeString("en-GB", {
      timeZone: "Europe/London",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };

  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="mt-32 max-w-5xl m-auto mx-auto p-4 md:p-10 font-sans bg-gray-50">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-6 mb-8">
          <div className="text-center md:text-left w-full md:w-2/3">
            <img
              src={citbLogo}
              alt="CITB Logo"
              className="w-[150px] mx-auto md:mx-0 h-auto mt-4 mb-6"
            />
            <h1 className="text-3xl md:text-4xl font-bold text-[#FF3131] mb-2">
              TWSTC Course
            </h1>
            <p className="text-lg text-gray-700">
              The TWSTC is a 1-day Course From CITB.
            </p>
          </div>
          <div className="flex flex-col gap-2 items-center w-full md:w-1/3">
            <ContactPageModal />
            <TrustpilotWidget
              templateId={"53aa8807dec7e10d38f59f32"}
              businessUnitId={"65e09121938229973209b5ac"}
            />
          </div>
        </div>

        {/* Card Section */}
        <div className="bg-[#0C1524] text-white rounded-xl max-w-4xl m-auto p-6 flex flex-col lg:flex-row gap-6 items-center shadow-lg">
          {/* Left Content */}
          <div className="w-full ml-4 lg:w-1/2">
            <h2 className="text-2xl mb-2 font-bold text-[#FF3131]">
              £{slot.product.price} + VAT
            </h2>
            <div className="space-y-4 text-lg">
              {/* Date Range */}
              <div className="flex items-center">
                <RxCalendar className="mr-2 text-white" size={20} />
                <span className="font-semibold">
                  {formatDateRange(slot.startDate, slot.endDate).dateRange}
                </span>
              </div>

              {/* Slot Type */}
              <div className="flex items-center">
                <IoLocationOutline className="mr-2 text-white" size={20} />
                <span className="font-semibold">{slot.type}</span>
              </div>

              {/* Start Time */}
              <div className="flex items-center">
                <AiOutlineClockCircle className="mr-2 text-white" size={20} />
                <span className="font-semibold">
                  {getTimeFromDate(slot.startDate)} to{" "}
                  {getTimeFromDate(slot.endDate)}
                </span>
              </div>

              {/* Remaining Slots */}
              <div className="flex items-center">
                <IoMdPeople className="mr-2 text-white" size={20} />
                <span className="font-semibold">{slot.seatsNo} slots left</span>
              </div>
            </div>

            <button
              onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(slot);
              }}
              className="mt-4 bg-[#FF3131] text-white font-semibold py-2 px-6 rounded hover:bg-[#FF4D4D] transition duration-300"
            >
              Add to Cart
            </button>
          </div>
          {/* Right Image */}
          <div className="w-full lg:w-1/2">
            <Calendar
              className="rounded-lg w-full"
              scheduleDates={slot.scheduleDates}
            />
          </div>
        </div>

        {/* Accordion Sections */}
        <div className="w-full max-w-4xl m-auto mt-10">
          {/* How It Works */}
          <div className="border-b py-4 bg-white rounded-lg shadow-sm mb-4">
            <button
              onClick={() => setHowItWorksOpen(!howItWorksOpen)}
              className="flex justify-between items-center w-full text-left font-semibold text-gray-800 text-lg px-4 py-2 hover:bg-gray-100 rounded transition"
            >
              How It Works
              {howItWorksOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <div
              className={`overflow-hidden transition-all duration-500 ${
                howItWorksOpen ? "max-h-screen" : "max-h-0"
              }`}
            >
              <div className="px-4 py-2 text-gray-600">
                <h1 className="font-bold mb-2 text-black">
                  Booking Confirmation
                </h1>
                <ul className="list-disc ml-4">
                  <li>
                  You’ll receive a confirmation email after booking. 
                  </li>
                  <li>
                  Ensure you have access to a Laptop/PC/Mac with a webcam and microphone for online training. 
                  </li>
                </ul>

                <h2 className="font-bold mb-1 text-black">
                  Pre-Course Materials & Access{" "}
                </h2>
                <ul className="list-disc ml-4">
                  <li>
                  2 days before the course (or immediately if booked last minute), you will receive: 
                    <ul className="list-disc ml-4">
                      <li>Link for online training.</li>
                      <li>Course instructions and preparation guide.</li>
                      <li>Digital resources to help you prepare.  </li>
                    </ul>
                  </li>
                </ul>

                <h2 className="font-bold mb-1 text-black">
                  Mandatory Online Form
                </h2>
                <ul className="list-disc ml-4">
                  <li>
                    A pre-course online form must be completed before training.
                  </li>
                </ul>

                <h2 className="font-bold mb-1 text-black">
                  Course Timing & Attendance{" "}
                </h2>
                <ul className="list-disc ml-4">
                  <li>Start Time: 8:30 AM sharp – punctuality is required.</li>
                  <li>🎓 Full attendance is mandatory.</li>
                  <li>
                    Late arrivals may not be allowed to continue, as per CITB
                    regulations.
                  </li>
                </ul>

                <h2 className="font-bold mb-1 text-black">
                  Identification Requirements
                </h2>
                <ul className="list-disc ml-4">
                  <li>
                    Bring a valid ID (passport or driving license) for identity
                    verification.
                  </li>
                </ul>

                <h2 className="font-bold mb-1 text-black">
                  📜 Assessment & Certification{" "}
                </h2>
                <ul className="list-disc ml-4">
                  <li>
                  Final assessment to be completed at the end of the course. 
                  </li>
                  <li>
                  Results sent within 1 day of course completion. 
                  </li>
                  <li>
                  Certificates issued within 14-21 working days. 
                  </li>
                  <li>
                    Plan accordingly for any employment or compliance
                    requirements.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="border-b py-4 bg-white rounded-lg shadow-sm">
            <button
              onClick={() => setAdditionalInfoOpen(!additionalInfoOpen)}
              className="flex justify-between items-center w-full text-left font-semibold text-gray-800 text-lg px-4 py-2 hover:bg-gray-100 rounded transition"
            >
              Additional Information
              {additionalInfoOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <div
              className={`overflow-hidden transition-all duration-500 ${
                additionalInfoOpen ? "max-h-screen" : "max-h-0"
              }`}
            >
              <div className="px-4 py-2 text-gray-600">
                <h2 className="font-bold mb-1 text-black">
                  Language Requirements:
                </h2>
                <p>
                  You must be able to speak, read, and write in English to
                  complete the course.
                </p>
                <h2 className="font-bold mb-1 text-black">
                  Technology Requirements:
                </h2>
                <p>
                  A stable internet connection that supports audio and video
                  conferencing is mandatory for online sessions.
                </p>
                <h2 className="font-bold mb-1 text-black">
                  Enrollment Information:
                </h2>
                <p>
                  Following these steps will ensure a smooth learning
                  experience, allowing directors to gain essential knowledge
                  while fulfilling their legal obligations. Enroll today to
                  secure your spot!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Course Details Section */}
        <div className="mt-10 flex flex-col md:flex-row gap-8">
          {/* Left Side: Course Details */}
          <div className="flex-[0.30]">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Course Details
            </h2>
            <p className="text-gray-600">
            The CITB Temporary Works Supervisor Training Course (TWSTC) is designed for individuals responsible for supervising temporary works within construction projects. This course provides an understanding of the legal duties, risk management, and best practices for overseeing temporary works safely and efficiently. 
            The course covers the following key areas: 
            </p>
          </div>

          {/* Vertical HR */}
          <div className="hidden md:block border-l border-gray-300"></div>

          {/* Right Side: Key Elements */}
          <div className="flex-[0.70]">
  <h3 className="text-xl font-semibold mb-4">
    The course covers the following key areas:
  </h3>
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-left">
    {[
      "Legal Responsibilities & Compliance: Understanding the role and responsibilities of a Temporary Works Supervisor (TWS).",
      "Key legislation, including BS 5975:2019 Code of Practice for Temporary Works Procedures.",
      "Duties related to safety, design, and coordination of temporary works.",
      "Temporary Works Supervision & Coordination: Ensuring effective communication between Temporary Works Coordinators (TWC) and site teams.",
      "Monitoring temporary works to prevent failures and safety risks.",
      "Supervising the correct implementation of temporary works on-site.",
      "Risk Management & Control Measures: Identifying key risks in temporary works and understanding failure consequences.",
      "Applying risk assessments and method statements (RAMS).",
      "Implementing effective control measures to ensure site safety.",
      "Business & Financial Impact of Poor Temporary Works Management: Understanding the financial and reputational damage caused by temporary works failures.",
      "Case studies of real-world temporary works incidents and lessons learned.",
      "Cost-benefit analysis of proactive supervision and compliance.",
      "Effective Communication & Team Collaboration: Working closely with the Temporary Works Coordinator (TWC).",
      "Best practices for engaging site teams and contractors in safety management.",
      "Ensuring compliance through structured supervision and clear reporting.",
    ].map((item, index) => (
      <div
        key={index}
        className="bg-[#0C1524] text-white p-4 rounded-xl hover:scale-105 transition-transform duration-300"
      >
        {item}
      </div>
    ))}
  </div>

  <p className="text-gray-600 mt-4">
    Note: Attendance for the full day is mandatory. Participants must pass the final assessment to receive their certification.
  </p>
</div>

        </div>

        {/* Why Choose SMSTS */}
        <div className="mt-10">
          <WhyToChooseTwstc />
        </div>

        <div className="mt-10 bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            What’s Included
          </h2>
          <div className="space-y-4">
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>Live training with CITB-approved industry professionals.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>
                  Digital course materials for reference before, during, and
                  after the course.{" "}
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>CITB certification upon successful completion. </p>
              </div>
            </div>
          </div>
          <p className="text-gray-600 mt-6">
            Stay compliant and lead your organization with confidence—📢 Enroll
            in our CITB Director’s Role for Health and Safety course today!
          </p>
        </div>

        {/* Footer */}
        <div className="mt-10 bg-gray-200 text-center p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold text-[#FF3131] mb-2">
            Completing the Course
          </h2>
          <p className="text-gray-800 text-lg mb-4">
          Successful candidates will receive the CITB Temporary Works Supervisor certificate, awarded upon completion and passing the final assessment. 
          </p>
          <p className="text-gray-800 text-lg mb-4">This certificate is:</p>
          <ul className="text-gray-800 text-lg list-disc list-inside mb-4">
            <li>
              <strong>Recognized Nationwide:</strong> Essential for supervisors overseeing temporary works. 
            </li>
            <li>
              <strong>Valid for 5 Years:</strong> Demonstrates commitment to safe temporary works management. Completing this course ensures compliance, safety, and competency in supervising temporary works. 
            </li>
          </ul>
          <p className="text-gray-800 text-lg">
            Completing this course, directors will ensure their businesses
            remain compliant, safe, and legally protected from potential
            liabilities.
          </p>
        </div>
      </div>
    </>
  );
};

export default TemporaryWorksSupervisionTrainingCourseDetail;
