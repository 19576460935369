import React, { useEffect, useRef } from "react";

const TrustpilotWidget = ({ templateId, businessUnitId }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      className="trustpilot-widget"
      data-locale="en-GB" // Adjust locale if needed
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height="150px"
      data-style-width="100%"
      ref={ref}>
      <a
        href="https://uk.trustpilot.com/review/m2hse.co.uk"
        target="_blank"
        rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotWidget;
