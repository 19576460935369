import React, { createContext, useState, useContext, useEffect } from "react";
import { useToast } from "./components/Toast/toast"; // Custom Toast Hook
import axios from "axios";

// Create Context
const CartContext = createContext({
  cartItems: {},
  addToCart: () => {},
  removeFromCart: () => {},
  clearCart: () => {},
  getTotalPrice: () => 0,
  getCartCount: () => 0,
});

// Cart Provider
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState({});
  const { addToast } = useToast();

  // Load cart items from localStorage on initial render
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || {};
    setCartItems(storedCart);
  }, []);

  // Add item to cart
  const addToCart = (product) => {
    const existingCart = cartItems;
    let existingItem = false;
    if (typeof product.isEmi == "boolean" && !product.isEmi) {
      existingCart.isEmi = false;
    }
    if (
      typeof product.productDetails === "object" ||
      product.type === "Online"
    ) {
      existingItem = existingCart?.items?.find(
        (item) => item?.slots === product._id
      );
    } else {
      existingItem = existingCart?.items?.find(
        (item) => item?.product === product._id
      );
    }
    if (existingItem) {
      addToast(
        `"${
          typeof product.name === "undefined" ? product.title : product.name
        }" already exist in your cart!`,
        "failed"
      );
      window.location.href = "/cart-checkout";
      return;
    } else {
      if (
        typeof product.productDetails === "object" ||
        product.type === "Online"
      ) {
        const inner_product =
          product.type === "Online" ? product.product : product?.productDetails;

        existingCart.items.push({
          href: window.location.pathname,
          product: inner_product?._id,
          total: inner_product?.price,
          itemName: inner_product?.name,
          price: inner_product?.price,
          description: inner_product?.description,
          quantity: 1,
          type: inner_product.type,
          slots: product._id,
        });
      } else {
        existingCart.items.push({
          href: window.location.pathname,
          product: product._id,
          total: product.price,
          itemName: product.name,
          price: product.price,
          description: product.description,
          quantity: 1,
          type: product.type,
        });
      }
    }
    // // console.log(existingCart);
    // existingCart.items = new Array();

    existingCart.total = existingCart.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total,
      0
    );

    setCartItems(existingCart);

    localStorage.setItem("cart", JSON.stringify(existingCart));

    axios
      .patch(`/cart/update/${existingCart._id}`, existingCart)
      .then((response) => {
        addToast(
          `"${
            typeof product.name === "undefined" ? product.title : product.name
          }" has been added to the cart!`,
          "success"
        );
        window.location.href = "/cart-checkout";
      })
      .catch((error) => {
        console.error("Error updating resource:", error);
      });
  };

  // Remove item from cart
  const removeFromCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || {};
    let updatedCartItems = [];

    // Filter out the product to be removed
    updatedCartItems = cartItems?.items?.filter(
      (item) => item?.product !== product.product
    );

    cartItems.items = updatedCartItems;

    // Check if isEmi is present in updatedCartItems
    const isEmiPresent = updatedCartItems.some((item) => item?.isEmi === false);
    if (!isEmiPresent) {
      cartItems.isEmi = true; // Set cartItems.isEmi to true if no item has isEmi
    }

    // Update the cart state and localStorage
    setCartItems(cartItems);
    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Send the updated cart to the server
    axios
      .patch(`/cart/update/${cartItems._id}`, cartItems)
      .then((response) => {
        addToast("Product removed from the cart!", "info");
      })
      .catch((error) => {
        console.error("Error updating resource:", error);
      });
  };

  // Clear entire cart
  const clearCart = () => {
    setCartItems({});
    localStorage.removeItem("cart");
    addToast("Cart cleared!", "info");
  };

  // Get total price
  const getTotalPrice = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || {};
    return cartItems?.items.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  // Get total item count
  const getCartCount = () => {
    return (
      cartItems?.items?.reduce((count, item) => count + item.quantity, 0) || 0
    );
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getTotalPrice,
        getCartCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom Hook to use Cart Context
export const useCart = () => useContext(CartContext);
