import React, { useState, useEffect } from "react";
import axios from "axios";
import useDebounce from "../../hooks/useDebounce";
import { ImSearch } from "react-icons/im";

const SearchComponent = () => {
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  // Use the useDebounce hook
  const [, cancelDebounce] = useDebounce(
    () => setDebouncedValue(searchTerm),
    500,
    [searchTerm]
  );

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setSearching(true);
        const options = {
          q: debouncedValue,
          fields: ["name", "description", "title"],
        };
        let query = "?";
        for (let key in options) {
          query += `${key}=${options[key]}&`;
        }
        query = query.slice(0, -1);

        const response = await axios.get(`product/search${query}`);
        setSearchResult(response.data?.result || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setSearching(false);
      }
    };

    if (debouncedValue) {
      fetchSearchResults();
    } else {
      setSearchResult([]);
      setSearching(false);
    }

    return () => cancelDebounce();
  }, [debouncedValue, cancelDebounce]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    setDebouncedValue(searchTerm);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setSearchTerm(item.name); // Assuming "name" is the display field
    window.location.href = item.detailsPage;
    setSearchResult([]);
  };

  return (
    <div className="w-full flex justify-center md:justify-end mt-2 md:mt-0">
      {/* Search Input */}
      <div className="relative w-full md:w-3/4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown} // Added keydown listener
          className="w-full px-4 py-2 rounded-full focus:outline-none text-black focus:ring focus:ring-blue-300"
        />
        <button
          className="absolute right-4 top-2 text-gray-800"
          name="Search Button"
          onClick={handleSearchClick}>
          <ImSearch />
        </button>
        {searchTerm && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-2 z-10">
            {searching ? (
              <div className="px-4 py-2 text-gray-600">... Searching</div>
            ) : searchResult.length > 0 ? (
              <ul>
                {searchResult.map((item) => (
                  <li
                    key={item.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelect(item)}>
                    {item.name} {/* Replace with your desired display field */}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="px-4 py-2 text-gray-600">No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
