import React, { startTransition, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/Courses/218dc37ca844a835bb8ab8f4e3b625bd.png";
import { TbArrowBadgeDownFilled } from "react-icons/tb";

const NvqCourses = () => {
  const [activeTab, setActiveTab] = useState("nvq"); // Default to NVQ
  const navigate = useNavigate();

  const handleCardClick = (level) => {
    if (level.id >= 8) {
      const healthRoutes = {
        8: "/nvq-level-3-health&safety",
        9: "/nvq-level-6-health&safety-practice",
        10: "/nvq-level-7-strategic-health-and-safety-leadership-and-management",
      };
      startTransition(() => {
        navigate(healthRoutes[level.id]);

      });
    } else {
      startTransition(() => {
        navigate(`/nvq-level-${level.id}`);
      });
    }
  };

  const nvqLevels = [
    {
      id: 2,
      title: "Level 2",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Blue CSCS card",
        "Instalment plans available",
      ],
    },
    {
      id: 3,
      title: "Level 3",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Gold CSCS card",
        "Instalment plans available",
      ],
    },
    {
      id: 4,
      title: "Level 4",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Gold CSCS card",
        "Instalment plans available",
      ],
    },
    {
      id: 5,
      title: "Level 5",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Black CSCS card",
        "Instalment plans available",
      ],
    },
    {
      id: 6,
      title: "Level 6",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Black CSCS card",
        "Instalment plans available",
      ],
    },
    {
      id: 7,
      title: "Level 7",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Leading to Black CSCS card",
        "Instalment plans available",
      ],
    },
  ];

  const healthSafetyLevels = [
    {
      id: 8,
      title: "Level 3",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Instalment plans available",
      ],
    },
    {
      id: 9,
      title: "Level 6",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Instalment plans available",
      ],
    },
    {
      id: 10,
      title: "Level 7",
      benefits: [
        "Klarna available at checkout",
        "All remotely online portfolio",
        "Fast track completion",
        "Instalment plans available",
      ],
    },
  ];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div
      className="p-6 mt-28 min-h-screen flex flex-col items-center justify-center relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0 bg-gray-900 opacity-90"
        aria-hidden="true"
      ></div>

      <div className="relative z-10 w-full max-w-5xl mb-6">
        {/* Tabs for selecting between NVQ and Health & Safety */}
        <div className="flex justify-center text-center space-x-6 mb-6">
          <button
            className={`flex items-center space-x-2 px-6 py-3 rounded-lg ${
              activeTab === "nvq"
                ? "bg-blue-600 text-white"
                : "bg-gray-800 text-gray-300"
            } transition duration-300`}
            onClick={() => {
              setActiveTab("nvq");
            }}
          >
            <span>NVQ Courses</span>
            <TbArrowBadgeDownFilled />
          </button>

          <button
            className={`flex items-center space-x-2 px-6 py-3 rounded-lg ${
              activeTab === "health"
                ? "bg-green-600 text-white"
                : "bg-gray-800 text-gray-300"
            } transition duration-300`}
            onClick={() => {
              setActiveTab("health");
            }}
          >
            <span>Health and Safety Courses</span>
            <TbArrowBadgeDownFilled />
          </button>
        </div>
        {/* Course Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {(activeTab === "nvq" ? nvqLevels : healthSafetyLevels).map(
            (level) => (
              <div
                key={level.id}
                className="p-6 rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 hover:shadow-lg hover:scale-105 transition-transform duration-300"
                onClick={() => handleCardClick(level)}
              >
                <h2 className="text-xl font-semibold text-gray-200">
                  {level.title}
                </h2>
                <ul className="text-sm text-gray-300 mt-4">
                  {level.benefits.map((benefit, index) => (
                    <li key={index} className="mt-1">
                      - {benefit}
                    </li>
                  ))}
                </ul>
                {/* Call to Action Button */}
                <button className="px-4 py-2 bg-[#FF3131] text-sm text-white font-bold rounded-xl shadow-md hover:bg-red-700 transition mt-4">
                  Enquire Now
                </button>
              </div>
            )
          )}
        </div>
      </div>
      </div>
    </Suspense>
  );
};

export default NvqCourses;
